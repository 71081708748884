import React, { useState, useEffect } from 'react';
import { useGetReportsByDateQuery, useGetReportsByDateRangeQuery } from './reportsApiSlice';
import { Container, Row, Col, Table, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel, faBell, faPenToSquare, faAngleDoubleLeft, faAngleDoubleRight, faFileLines } from "@fortawesome/free-solid-svg-icons";
import PulseLoader from 'react-spinners/PulseLoader';
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const ReportsByDate = () => {
    const { username, isManager, isAdmin, isQA } = useAuth();

    const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
    const [completionFilter, setCompletionFilter] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [showMyReports, setShowMyReports] = useState(localStorage.getItem('myOriginated') === 'true' || isManager);
    const [reports, setReports] = useState([]);
    const [totalReports, setTotalReports] = useState(0);
    const [totalReportsForDate, setTotalReportsForDate] = useState(0);
    const [error, setError] = useState(null);

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [offset, setOffset] = useState(0);
    const limit = 500;

    const { data, isLoading, isFetching, refetch, status } = useGetReportsByDateQuery(date, {
        skip: !date,
        onError: setError
    });

    // eslint-disable-next-line
    const { data: rangeData, isLoading: isRangeLoading, isFetching: isRangeFetching, refetch: rangeRefetch } = useGetReportsByDateRangeQuery({ startDate, endDate, offset, limit }, {
        skip: !startDate || !endDate,
        onError: setError
    });

    useEffect(() => {
        if (data) {
            const { ids, entities, totalReports, totalReportsForDate } = data;
            const fetchedReports = ids.map(id => entities[id]);
            setReports(fetchedReports);
            setTotalReports(totalReports || 0);
            setTotalReportsForDate(totalReportsForDate || 0);
        }
    }, [data]);

    useEffect(() => {
        if (rangeData && startDate && endDate) {
            const { ids, entities, totalReports } = rangeData;
            const fetchedReports = ids.map(id => entities[id]);
            setReports(prevReports => [...prevReports, ...fetchedReports]);
            setTotalReports(totalReports || 0);
        }
    }, [rangeData, startDate, endDate]);

    useEffect(() => {
        if (startDate && endDate) {
            setDate('');
            setReports([]);
        }
    }, [startDate, endDate]);

    useEffect(() => {
        if (date) {
            if (status === 'success') {
                refetch();
            }
        }
    }, [date, status, refetch]);

    const handleLoadMore = () => {
        setOffset(prevOffset => prevOffset + limit);
    };

    const navigate = useNavigate();

    const formatTAT = (tat) => {
        if (tat === null) {
            return "N/A";
        }

        if (tat === 0) {
            return "00:00";
        }

        const hours = Math.floor(tat / 60);
        const minutes = tat % 60;

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    };

    const handleDateChange = (e) => {
        setDate(e.target.value);
        setStartDate('');
        setEndDate('');
    };

    const filteredReports = reports.filter(report => {
        const originatorMatches = showMyReports ? report.originator.toLowerCase() === username.toLowerCase() : true;
        const completionMatches = completionFilter ? report.completed === completionFilter : true;

        return (
            (isManager || isAdmin || isQA ||
                report.username === username ||
                report.access_ids.includes(username)) &&
            (report.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                report.accessionno.toLowerCase().includes(searchTerm.toLowerCase()) ||
                report.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
                report.originator.toLowerCase().includes(searchTerm.toLowerCase()) ||
                report.priority.toLowerCase().includes(searchTerm.toLowerCase()) ||
                report.modality.toLowerCase().includes(searchTerm.toLowerCase()) ||
                report.institute.toLowerCase().includes(searchTerm.toLowerCase())) &&
            originatorMatches &&
            completionMatches
        );
    });

    const handleButtonClick = () => {
        setShowMyReports(!showMyReports);
        localStorage.setItem('myOriginated', !showMyReports);
    };

    const exportToExcel = () => {
        let wb = XLSX.utils.book_new();
        let ws_name = `${username}_worklist`;

        let ws_data = [['Assigned', 'Accession No', 'Originator', 'Priority', 'Modality', 'Title', 'DOB', 'Sex', 'Institute', 'Status', 'Access IDs', 'Created At', 'Updated At']];

        filteredReports.forEach(report => {
            let access_ids = report.access_ids.length > 0 ? report.access_ids.join(',') : 'N/A';
            let institute = report.institute.length > 0 ? report.institute : 'N/A';
            let dob = report?.dob ? report.dob : 'N/A';
            let sex = report?.sex ? report.sex : 'N/A';
            let completedStatus = report.completed === "true" ? "Completed" : report.completed === "false" ? "Open" : report.completed;

            ws_data.push([report.username, report.accessionno, report.originator, report.priority, report.modality, report.title, dob, sex, institute, completedStatus, access_ids, report.createdat, report.updatedat]);
        });

        let ws = XLSX.utils.aoa_to_sheet(ws_data);
        XLSX.utils.book_append_sheet(wb, ws, ws_name);
        XLSX.writeFile(wb, `${username}_worklist.xlsx`);
    };

    const handlePagination = (direction) => {
        const currentDate = new Date();
        const maxDate = new Date('2024-05-15T00:00:00.000Z');
        const minDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);

        if (direction === 'prev') {
            const newDate = new Date(date);
            newDate.setDate(newDate.getDate() - 1);
            if (newDate >= maxDate) {
                setDate(newDate.toISOString().split('T')[0]);
            }
        } else if (direction === 'next') {
            const newDate = new Date(date);
            newDate.setDate(newDate.getDate() + 1);
            if (newDate <= minDate) {
                setDate(newDate.toISOString().split('T')[0]);
            }
        }
    };

    const openReports = filteredReports.filter(report => report.completed === "false").length;
    const addendumReports = filteredReports.filter(report => report.completed === "Addendum").length;
    const finalReports = filteredReports.filter(report => report.completed === "Final").length;
    const completedReports = filteredReports.filter(report => report.completed === "true").length;
    const approvedReports = filteredReports.filter(report => report.completed === "Approved").length;
    const readReports = filteredReports.filter(report => report.completed === "Read").length;


    const tableContent = filteredReports.map(report => {
        const created = new Date(report.createdat).toLocaleString('en-US', { day: 'numeric', month: 'long', hour: 'numeric', minute: 'numeric' });
        const updated = new Date(report.updatedat).toLocaleString('en-US', { day: 'numeric', month: 'long', hour: 'numeric', minute: 'numeric' });
        const truncatedTitle = report.title.length > 20 ? `${report.title.slice(0, 20)}...` : report.title;
        const priorityColor = report.priority === 'STAT' ? '#a30000cd' : report.priority === 'ASAP' ? '#008080cd' : 'black';

        const handleEdit = () => navigate(`/dash/report/${report._id}`);

        const getBackgroundColor = () => {
            // if (!darkTheme) {
            //     if (report.priority === 'ASAP') {
            //         return '#E7FFCE'; // light green for ASAP
            //     } else if (report.priority === 'STAT') {
            //         return '#ffe5e5'; // light red for STAT
            //     }
            // }
            // if (darkTheme) {
            if (report.priority === 'ASAP') {
                return 'rgba(0, 100, 0, 0.3)'; // light green for ASAP
            } else if (report.priority === 'STAT') {
                return 'rgba(139, 0, 0, 0.3)'; // light red for STAT
            }
            // }
        };

        return (
            <tr key={report._id}>
                <td style={{
                    backgroundColor: getBackgroundColor(),
                }} className='darktablecontent'>
                    {
                        report.completed === "true" ? <span style={{ color: '#008080cd', marginRight: '5px' }}>Completed</span> :
                            report.completed === "false" ? <span style={{ color: '#a30000cd', marginRight: '5px' }}>Open</span> :
                                report.completed === "Read" ? <span style={{ color: 'black', marginRight: '5px' }}>Read</span> :
                                    report.completed === "Approved" ? <span style={{ color: 'green', marginRight: '5px' }}>Approved</span> :
                                        report.completed === "Addendum" ? <span style={{ color: 'orange', marginRight: '5px' }}>Addendum</span> :
                                            report.completed === "Final" ? <span style={{ color: '#3c3c6b', marginRight: '5px' }}>Final</span> :
                                                <span style={{ color: '#000', marginRight: '5px' }}>{report.completed}</span>
                    }
                    {(report.username === username && report.notification === "2") && <FontAwesomeIcon icon={faBell} style={{ color: 'gray' }} />}
                    {(report.username !== username && report.notification === "1") && <FontAwesomeIcon icon={faBell} style={{ color: 'gray' }} />}
                </td>

                <td style={{
                    backgroundColor: getBackgroundColor(),
                    color: '#008080'
                }} className='darktablecontent'>{formatTAT(report.tat)}</td>

                <td style={{
                    backgroundColor: getBackgroundColor(),
                }} className='darktablecontent'>{created}</td>

                <td style={{
                    backgroundColor: getBackgroundColor(),
                }} className='darktablecontent'>{updated}</td>

                <td style={{
                    backgroundColor: getBackgroundColor(), color: priorityColor
                }} className='darktablecontent' >{report.priority}</td>

                <td style={{
                    backgroundColor: getBackgroundColor(),
                }} className='darktablecontent'>{report.modality}</td>

                <td style={{
                    backgroundColor: getBackgroundColor(),
                }} title={report.title} className='darktablecontent'>{truncatedTitle}</td>

                <td style={{
                    backgroundColor: getBackgroundColor(),
                }} className='darktablecontent'>{report.accessionno}</td>

                <td style={{
                    backgroundColor: getBackgroundColor(),
                }} className='darktablecontent'>{!report.dob ? "N/A" : report.dob}</td>

                <td style={{
                    backgroundColor: getBackgroundColor(),
                }} className='darktablecontent'>{!report.sex ? "N/A" : report.sex}</td>

                <td style={{
                    backgroundColor: getBackgroundColor(),
                }} className='darktablecontent'>{report.institute === "" ? "N/A" : report.institute}</td>

                <td style={{
                    backgroundColor: getBackgroundColor(),
                }} className='darktablecontent'>{report.username}</td>

                <td style={{
                    backgroundColor: getBackgroundColor(),
                }} className='darktablecontent'>
                    <button
                        onClick={handleEdit}
                        className='edit-button'
                        title="Edit"
                    >
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                </td>
            </tr>
        );
    })

    return (
        <>
            <Container fluid className="reports-list">

                <Row>
                    <Col xs={12}>
                        <div className="search-input-reports-list">
                            <div className="input-group mb-3">
                                <select
                                    as="select"
                                    style={{ marginBottom: "10px", padding: "0 10px", marginRight: "5px", marginTop: "5px", border: "1px solid #ccc" }}
                                    value={completionFilter}
                                    title="Reports status"
                                    onChange={e => setCompletionFilter(e.target.value)}
                                    className="input-list-select"
                                >
                                    <option value="">All</option>
                                    <option value="true">Completed</option>
                                    <option value="false">Open</option>
                                    <option value="Approved">Approved</option>
                                    <option value="Read">Read</option>
                                    <option value="Addendum">Addendum</option>
                                    <option value="Final">Final</option>
                                </select>

                                <input type="date" className="input-list" id="start-date" title="Start date" value={startDate} style={{ marginBottom: "10px" }} onChange={e => setStartDate(e.target.value)} />

                                <input type="date" className="input-list" id="end-date" title="End date" value={endDate} style={{ marginBottom: "10px" }} onChange={e => setEndDate(e.target.value)} />

                                <input
                                    placeholder="Search..."
                                    aria-label="Search"
                                    aria-describedby="basic-addon2"
                                    className="input-list"
                                    title="Search reports"
                                    value={searchTerm}
                                    onChange={e => setSearchTerm(e.target.value)}
                                    style={{ marginBottom: "10px" }}
                                />

                                <input
                                    type="date"
                                    className="input-list"
                                    value={date}
                                    title="Input date"
                                    onChange={handleDateChange}
                                    style={{ marginBottom: "10px" }}
                                />

                                <button
                                    onClick={exportToExcel}
                                    className="excel-btn"
                                    variant="outline-secondary"
                                    title="export to excel"
                                    style={{ marginTop: "5px" }}
                                >
                                    Export <FontAwesomeIcon icon={faFileExcel} style={{ marginLeft: "5px" }} />
                                </button>

                                <button
                                    className={`my-assignment-button ${showMyReports ? "my-assignment-green" : "my-assignment-gray"}`}
                                    style={{ marginRight: "5px", marginTop: "5px" }}
                                    title="My assignments"
                                    onClick={handleButtonClick}
                                >
                                    <FontAwesomeIcon icon={faFileLines} />
                                </button>
                                <button
                                    onClick={() => handlePagination('prev')}
                                    variant="outline-secondary"
                                    title="Previous date"
                                    className="excel-btn dark-1btn"
                                    style={{ marginTop: "5px" }}
                                    disabled={isLoading || isFetching || isRangeLoading || isRangeFetching}
                                >
                                    <FontAwesomeIcon icon={faAngleDoubleLeft} />
                                </button>
                                <button
                                    onClick={() => handlePagination('next')}
                                    variant="outline-secondary"
                                    title="Next date"
                                    className="excel-btn dark-1btn"
                                    style={{ marginTop: "5px", borderRadius: '0' }}
                                    disabled={isLoading || isFetching || isRangeLoading || isRangeFetching}
                                >
                                    <FontAwesomeIcon icon={faAngleDoubleRight} />
                                </button>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Card className="mb-2 dateDatadark" style={{ border: 'none', borderBottom: '1px solid #ccc' }}>
                            <Card.Body>
                                <Row>
                                    {(isAdmin || isManager || isQA) && (
                                        <Col className="mb-1 col-border" style={{ color: '#717378cd' }}>
                                            <strong>Total Reports:</strong> {totalReports}
                                        </Col>
                                    )}
                                    {(isAdmin || isManager || isQA) && (!startDate && !endDate) && (
                                        <Col className="mb-1 col-border" style={{ color: '#717378cd' }}>
                                            <strong>Date Reports: </strong> {totalReportsForDate}
                                        </Col>
                                    )}
                                    <Col className="mb-1 col-border" style={{ color: '#717378cd' }}>
                                        <strong>Open:</strong> {openReports}
                                    </Col>
                                    <Col className="mb-1 col-border" style={{ color: '#717378cd' }}>
                                        <strong>Addendum:</strong> {addendumReports}
                                    </Col>
                                    <Col className="mb-1 col-border" style={{ color: '#717378cd' }}>
                                        <strong>Final:</strong> {finalReports}
                                    </Col>
                                    <Col className="mb-1 col-border" style={{ color: '#717378cd' }}>
                                        <strong>Completed:</strong> {completedReports}
                                    </Col>
                                    <Col className="mb-1 col-border" style={{ color: '#717378cd' }}>
                                        <strong>Approved:</strong> {approvedReports}
                                    </Col>
                                    <Col className="mb-1 col-border" style={{ color: '#717378cd' }}>
                                        <strong>Read:</strong> {readReports}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        {(isLoading || isFetching || isRangeLoading || isRangeFetching) ? (
                            <div className="loading-overlay">
                                <PulseLoader color={"#1f2a4b"} />
                            </div>
                        ) : error ? (
                            <p>{error}</p>
                        ) : (
                            <Table striped hover responsive >
                                <thead>
                                    <tr>
                                        <th scope="col" className="darktable" >Status</th>
                                        <th scope="col" className="darktable" >TAT</th>
                                        <th scope="col" className="darktable" >Created</th>
                                        <th scope="col" className="darktable" >Updated</th>
                                        <th scope="col" className="darktable" >Priority</th>
                                        <th scope="col" className="darktable" >Modality</th>
                                        <th scope="col" className="darktable" >Title</th>
                                        <th scope="col" className="darktable" >Accession</th>
                                        <th scope="col" className="darktable" >DOB</th>
                                        <th scope="col" className="darktable" >Sex</th>
                                        <th scope="col" className="darktable" >Institution</th>
                                        <th scope="col" className="darktable" >Assigned</th>
                                        <th scope="col" className="darktable" >Edit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableContent}
                                </tbody>
                            </Table>
                        )}

                        {(filteredReports?.length === 0 && !startDate && !endDate) && (
                            <div className="content" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
                                <p style={{ color: '#a30000cd' }}>
                                    {`No report found on ${date}`}
                                </p>
                            </div>
                        )}

                        {(filteredReports?.length === 0 && startDate && endDate) && (
                            <div className="content" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
                                <p style={{ color: '#a30000cd' }}>
                                    {`No report found from ${startDate} to ${endDate}`}
                                </p>
                            </div>
                        )}

                        {(offset + limit < totalReports) && (startDate && endDate) && (
                            <Row className="mb-3 justify-content-center">
                                <Col className="text-center">
                                    <div className="content" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
                                        <button
                                            variant="outline-secondary"
                                            title="Load more reports"
                                            className="excel-btn dark-1btn"
                                            style={{ marginTop: "5px", borderRadius: '0' }}
                                            disabled={isLoading || isFetching || isRangeLoading || isRangeFetching}
                                            onClick={handleLoadMore}>Load More</button>
                                    </div>
                                </Col>
                            </Row>
                        )}

                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default ReportsByDate;