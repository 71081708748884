// LogOps.js
import React, { useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useGetLogFilesQuery, useGetLogFileContentQuery } from './logApiSlice';
import Highlighter from 'react-highlight-words';
import useTitle from '../../hooks/useTitle';

const LogOps = () => {
    useTitle(`telerapp - System Logs`);

    const [selectedFile, setSelectedFile] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    const handleFileChange = (e) => {
        setSelectedFile(e.target.value);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const { data: logFiles } = useGetLogFilesQuery();

    const {
        data: logFileContent,
        error: logFileContentError,
        isLoading: logFileContentLoading,
    } = useGetLogFileContentQuery(selectedFile, { skip: !selectedFile });

    let content = '';
    if (logFileContent && !logFileContentLoading && !logFileContentError) {
        content = logFileContent.data;
    }

    return (
        <Container style={{ paddingTop: '6rem', paddingBottom: '7rem' }}>
            <Row className="justify-content-md-center">
                <Col xs={12} md={10} lg={10}>
                    <label style={{ fontWeight: 'bold' }}>Select Log File : </label>
                    <Card style={{ border: 'none' }} className="mb-3 darktEditUsercontent">
                        <Card.Body>
                            <form>
                                <select
                                    value={selectedFile || ''}
                                    onChange={handleFileChange}
                                    title="Select logs"
                                    style={{ borderRadius: '6px', padding: '4px 8px', border: '2px solid #ccc', marginRight: '10px' }}
                                    required
                                >
                                    <option value="">Select a log file</option>
                                    {logFiles?.map(file => (
                                        <option key={file} value={file}>{file}</option>
                                    ))}
                                </select>

                                <input
                                    type="text"
                                    placeholder="Search term"
                                    title="Search on logs data"
                                    style={{ borderRadius: '6px', padding: '4px 8px', border: '2px solid #ccc', marginRight: '10px', marginTop: '5px' }}
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                    required
                                />

                            </form>
                        </Card.Body>
                    </Card>

                    <div className="log-content" style={{ width: '100%', height: '25rem', overflow: 'auto' }}>
                        <pre title="Logs date" style={{ width: '100%', height: '95vh', overflow: 'auto' }}>
                            <Highlighter
                                searchWords={[searchTerm]}
                                autoEscape={true}
                                textToHighlight={content}
                            />
                        </pre>
                    </div>

                </Col>
            </Row>
        </Container>
    );
}

export default LogOps;
