import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faCircleNodes, faCircleUser, faCircleXmark, faPenToSquare } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from 'react-router-dom'
import { useGetUsersQuery } from './usersApiSlice'
import { memo } from 'react'

const User = ({ userId }) => {

    const { user } = useGetUsersQuery("usersList", {
        selectFromResult: ({ data }) => ({
            user: data?.entities[userId]
        }),
    })

    const navigate = useNavigate()

    if (user) {
        const handleEdit = () => navigate(`/dash/users/${userId}`)

        const userRolesString = user.roles.toString().replaceAll(',', ', ')

        const lastActive = new Date(user.last_active);
        const now = new Date();
        const diffInMinutes = (now - lastActive) / 1000 / 60;
        const isOnline = diffInMinutes <= 15;

        return (
            <tr>
                <td className='darktablecontent'>{(isOnline === true ? <FontAwesomeIcon title='Online' icon={faCircleUser} style={{ color: "#008080cd", }} />
                    : <FontAwesomeIcon icon={faCircleUser} title='Offline' style={{ color: "#a30000cd", }} />)}
                    <span style={{ marginLeft: '10px' }}>{user.username}</span> {'  '}
                    {(user.annotationviewer === true ? <FontAwesomeIcon icon={faCircleNodes} title='Annotation on' style={{ color: "#008080cd", }} />
                        : <FontAwesomeIcon icon={faCircleNodes} title='Annotation off' style={{ color: "#a30000cd", }} />)}</td>
                <td className='darktablecontent'>{user.fullname}</td>
                <td className='darktablecontent'>{user.active ? <FontAwesomeIcon style={{ color: '#008080cd' }} icon={faCircleCheck} /> : <FontAwesomeIcon style={{ color: '#a30000cd' }} icon={faCircleXmark} />}</td>
                <td className='darktablecontent'>{userRolesString}</td>
                <td className='darktablecontent'>
                    <button
                        onClick={handleEdit}
                        className='edit-button'
                        title="Edit"
                    >
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                </td>
            </tr>
        )

    } else return null
}

const memoizedUser = memo(User)

export default memoizedUser