import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"

const reportsAdapter = createEntityAdapter({
    sortComparer: (a, b) => {
        // First, compare based on the completion status
        if (a.completed === "Approved" && b.completed !== "Approved") {
            return 1;
        }
        if (a.completed !== "Approved" && b.completed === "Approved") {
            return -1;
        }

        // If the completion status is the same, compare based on the creation date
        if (a.createdat !== b.createdat) {
            // Convert the date strings to Date objects for comparison
            const dateA = new Date(a.createdat);
            const dateB = new Date(b.createdat);

            // Sort in descending order based on creation date
            return dateB - dateA;
        }

        // If both completion status and creation date are the same, consider them equal
        return 0;
    }
});

const initialState = reportsAdapter.getInitialState()

export const reportsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getReports: builder.query({
            query: () => ({
                url: '/backend/reports/all-reports',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedReports = responseData.map(report => {
                    report.id = report._id
                    return report
                });
                return reportsAdapter.setAll(initialState, loadedReports)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Report', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Report', id }))
                    ]
                } else return [{ type: 'Report', id: 'LIST' }]
            }
        }),
        getReportsByDate: builder.query({
            query: (date) => ({
                url: '/backend/reports/by-date',
                method: 'POST',
                body: { date },
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedReports = responseData.reportsWithUser ? responseData.reportsWithUser.map(report => {
                    report.id = report._id;
                    return report;
                }) : [];
                return {
                    totalReports: responseData.totalReports,
                    totalReportsForDate: responseData.totalReportsForDate,
                    ...reportsAdapter.setAll(initialState, loadedReports)
                };
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Report', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Report', id }))
                    ]
                } else return [{ type: 'Report', id: 'LIST' }]
            }
        }),
        getReportsByDateRange: builder.query({
            query: ({ startDate, endDate, offset = 0, limit = 500 }) => ({
                url: '/backend/reports/date-range',
                method: 'POST',
                body: { startDate, endDate, offset, limit },
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            transformResponse: responseData => {
                const loadedReports = responseData.reports ? responseData.reports.map(report => {
                    report.id = report._id;
                    return report;
                }) : [];
                return {
                    ...reportsAdapter.setAll(initialState, loadedReports),
                    totalReports: responseData.totalReports,
                };
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Report', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Report', id }))
                    ];
                } else return [{ type: 'Report', id: 'LIST' }];
            }
        }),


        addNewReport: builder.mutation({
            query: initialReport => ({
                url: '/backend/reports/new-report',
                method: 'POST',
                body: {
                    ...initialReport,
                }
            }),
            invalidatesTags: [
                { type: 'Report', id: "LIST" }
            ]
        }),
        getReportData: builder.mutation({
            query: reportId => ({
                url: `/backend/reports/report-data/${reportId}`,
                method: 'GET'
            }),
            transformResponse: responseData => responseData,
        }),
        updateReport: builder.mutation({
            query: initialReport => ({
                url: '/backend/reports/update-report',
                method: 'PATCH',
                body: {
                    ...initialReport,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Report', id: arg.id }
            ]
        }),
        updateNotification: builder.mutation({
            query: initialReport => ({
                url: '/backend/reports/update-notification',
                method: 'PATCH',
                body: {
                    ...initialReport,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Report', id: arg.id }
            ]
        }),
        deleteReport: builder.mutation({
            query: initialReport => ({
                url: `/backend/reports/delete-report`,
                method: 'DELETE',
                body: {
                    ...initialReport
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Report', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetReportsQuery,
    useGetReportsByDateQuery,
    useGetReportsByDateRangeQuery,
    useAddNewReportMutation,
    useUpdateReportMutation,
    useDeleteReportMutation,
    useUpdateNotificationMutation,
    useGetReportDataMutation,
} = reportsApiSlice

// returns the query result object
export const selectReportsResult = reportsApiSlice.endpoints.getReports.select()

// creates memoized selector
const selectReportsData = createSelector(
    selectReportsResult,
    reportsResult => reportsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllReports,
    selectById: selectReportById,
    selectIds: selectReportIds
    // Pass in a selector that returns the reports slice of state
} = reportsAdapter.getSelectors(state => selectReportsData(state) ?? initialState)