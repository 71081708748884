import { useState, useEffect, useRef } from "react"
import { useAddNewUserMutation } from "./usersApiSlice"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from "@fortawesome/free-solid-svg-icons"
import { ROLES } from "../../config/roles"
import useTitle from "../../hooks/useTitle"
import { Container, Row, Col, Card } from 'react-bootstrap'
import Modal from '../../components/Modal'

const USER_REGEX = /^[A-z0-9!@#$%]{6,30}$/
const FULLNAME_REGEX = /^[A-z0-9!@#$% ]{6,255}$/
const PWD_REGEX = /^[A-z0-9!@#$%]{6,12}$/

const NewUserForm = () => {
    useTitle('telerapp - New User')

    const [addNewUser, {
        isLoading,
        isSuccess,
        // eslint-disable-next-line
        isError,
        error
    }] = useAddNewUserMutation()

    const navigate = useNavigate()

    useEffect(() => {
        if (error) {
            setErrMsg(error?.data?.message || 'Failed to save user.');
            setShowModal(true);
        }
    }, [error]);

    const [username, setUsername] = useState('')
    const [fullname, setFullname] = useState('')
    const [validUsername, setValidUsername] = useState(false)
    const [validFullname, setValidFullname] = useState(false)
    const [password, setPassword] = useState('')
    const [validPassword, setValidPassword] = useState(false)
    const [roles, setRoles] = useState(["Consultant"])

    const usernameRef = useRef(null);
    const passwordRef = useRef(null);
    const fullnameRef = useRef(null);

    const [errMsg, setErrMsg] = useState('');
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (isSuccess) {
            setUsername('')
            setFullname('')
            setPassword('')
            setRoles([])
            navigate('/dash/users')
        }
    }, [isSuccess, navigate])

    const handleCloseModal = () => {
        setShowModal(false);
        setErrMsg('');
    };

    const onUsernameChanged = e => {
        const newUsername = e.target.value;
        setUsername(newUsername);
        setValidUsername(USER_REGEX.test(newUsername));
        if (!USER_REGEX.test(newUsername)) {
            setErrMsg('Minimum 6 - 30 characters and no space.');
            usernameRef.current.focus();
        } else {
            setErrMsg('');
        }
    };

    const onFullnameChanged = e => {
        const newFullname = e.target.value;
        setFullname(newFullname);
        setValidFullname(FULLNAME_REGEX.test(newFullname));
        if (!FULLNAME_REGEX.test(newFullname)) {
            setErrMsg('Minimum 6 - 255 characters.');
            fullnameRef.current.focus();
        } else {
            setErrMsg('');
        }
    };

    const onPasswordChanged = e => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        setValidPassword(PWD_REGEX.test(newPassword));
        if (!PWD_REGEX.test(newPassword)) {
            setErrMsg('Minimum 6 - 12 characters and no space.');
            passwordRef.current.focus();
        } else {
            setErrMsg('');
        }
    };

    const onRolesChanged = e => {
        const values = Array.from(
            e.target.selectedOptions,
            (option) => option.value
        )
        setRoles(values)
    }

    const canSave = [roles.length, validUsername, validFullname, validPassword].every(Boolean) && !isLoading

    const onSaveUserClicked = async (e) => {
        e.preventDefault()
        if (canSave) {
            await addNewUser({ username, fullname, password, roles })
        }
    }

    const options = Object.values(ROLES).map(role => {
        return (
            <option
                key={role}
                value={role}

            > {role}</option >
        )
    })


    const content = (
        <>
            <Container style={{ paddingTop: '6rem', paddingBottom: '7rem' }}>
                <Row className="justify-content-md-center">
                    <Col xs={12} md={8} lg={4}>

                        <form onSubmit={onSaveUserClicked}>
                            <Card className="mb-3 darktEditUsercontent">
                                <Card.Body>

                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <button
                                            title="Save"
                                            className="user-add-button"
                                            disabled={!canSave || isLoading}
                                        >
                                            <FontAwesomeIcon icon={faSave} /> {isLoading ? 'Saving...' : `Save`}
                                        </button>
                                    </div>
                                    <label style={{ fontWeight: 'bold', display: 'block', marginTop: '10px' }}>Add New User : </label>

                                    <input
                                        id="username"
                                        name="username"
                                        title="Username"
                                        type="text"
                                        placeholder="UserId"
                                        style={{ borderRadius: '6px', padding: '4px 8px', border: '2px solid #ccc', marginRight: '10px', display: 'block', marginTop: '10px' }}
                                        autoComplete="off"
                                        value={username}
                                        onChange={onUsernameChanged}
                                        ref={usernameRef}
                                        className={!validUsername ? 'error-input' : ''}
                                    />
                                    {!validUsername && <span className="error-message">Minimum 6 - 30 characters no space.</span>}

                                    <input
                                        id="fullname"
                                        name="fullname"
                                        title="Fullname"
                                        type="text"
                                        placeholder="Fullname"
                                        style={{ borderRadius: '6px', padding: '4px 8px', border: '2px solid #ccc', marginRight: '10px', display: 'block', marginTop: '10px' }}
                                        autoComplete="off"
                                        value={fullname}
                                        onChange={onFullnameChanged}
                                        ref={fullnameRef}
                                        className={!validFullname ? 'error-input' : ''}
                                    />
                                    {!validFullname && <span className="error-message">Minimum 6 - 255 characters.</span>}

                                    <input
                                        id="password"
                                        name="password"
                                        title="Password"
                                        type="password"
                                        placeholder="Password"
                                        style={{ borderRadius: '6px', padding: '4px 8px', border: '2px solid #ccc', marginRight: '10px', display: 'block', marginTop: '10px' }}
                                        value={password}
                                        onChange={onPasswordChanged}
                                        ref={passwordRef}
                                        className={!validPassword ? 'error-input' : ''}
                                    />
                                    {!validPassword && <span className="error-message">Minimum 6 - 12 characters no space.</span>}

                                    <label style={{ fontWeight: 'bold', display: 'block', marginTop: '10px' }}>Select Authorization : </label>
                                    <select
                                        id="roles"
                                        name="roles"
                                        title="Roles"
                                        multiple={true}
                                        size="3"
                                        value={roles}
                                        onChange={onRolesChanged}
                                        style={{ display: 'block', marginTop: '10px' }}
                                    >
                                        {options}
                                    </select>
                                </Card.Body>
                            </Card>
                        </form>
                    </Col>
                </Row>
            </Container>
            <Modal show={showModal} onClose={handleCloseModal}>
                <p>{errMsg}</p>
            </Modal>
        </>
    )

    return content
}
export default NewUserForm