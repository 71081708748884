import { apiSlice } from "../../app/api/apiSlice"

export const tempApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        createNewTemplate: builder.mutation({
            query: ({ filename, content }) => ({
                url: '/backend/temp/create-template',
                method: 'POST',
                body: { template_name: filename, template_content: content }
            }),
            invalidatesTags: [
                { type: 'Temp', id: "LIST" }
            ]
        }),
        deleteATemplate: builder.mutation({
            query: (body) => ({
                url: `/backend/temp/delete-template`,
                method: 'DELETE',
                body: { name: body.filename }, // Send template name in the body
            }),
            invalidatesTags: [{ type: 'Temp', id: "LIST" }],
        }),

        getTemplates: builder.query({
            query: () => '/backend/temp/templates',
        }),
        getTemplateContent: builder.query({
            query: (fileName) => `/backend/temp/template-data/${fileName}`,
        }),
    }),
})

export const {
    useCreateNewTemplateMutation,
    useDeleteATemplateMutation,
    useGetTemplatesQuery,
    useGetTemplateContentQuery,
} = tempApiSlice
