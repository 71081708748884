import { useGetUsersQuery } from "./usersApiSlice"
import User from './User'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'
import React, { useState } from "react"
import { Container, Row, Col, Table } from 'react-bootstrap';

const UsersList = () => {
    useTitle('telerapp - All Users')

    const {
        data: users,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetUsersQuery('usersList', {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const [searchTerm, setSearchTerm] = useState('');

    let content

    if (isLoading) content = (
        <div className="loading-overlay">
            <PulseLoader color={"#1f2a4b"} />
        </div>
    )

    if (isError) {
        content = (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', color: '#717378cd' }}>
                <p>{error?.data?.message}</p>
            </div>
        )
    }

    if (isSuccess) {

        let { ids, entities } = users

        ids = ids.filter(userId => entities[userId].username.toLowerCase().includes(searchTerm.toLowerCase()))

        const tableContent = ids?.length
            && ids.map(userId => <User key={userId} userId={userId} />)

        content = (
            <Container fluid className="users-list">
                <Row>
                    <Col xs={12}>
                        <div className="mb-3 input-group search-input-users-list">
                            <input
                                style={{ marginBottom: "10px" }}
                                placeholder="Search..."
                                aria-label="Search"
                                aria-describedby="basic-addon2"
                                title="Search user"
                                value={searchTerm}
                                onChange={event => setSearchTerm(event.target.value)}
                            />
                        </div>
                        <Table striped hover responsive>
                            <thead>
                                <tr>
                                    <th className="darktable">Username</th>
                                    <th className="darktable">Full Name</th>
                                    <th className="darktable">Active</th>
                                    <th className="darktable">Roles</th>
                                    <th className="darktable">Edit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContent}
                            </tbody>
                        </Table>
                        {!ids?.length && (
                            <div className="content" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
                                <p style={{ color: '#a30000cd' }}>
                                    {`No user found`}
                                </p>
                            </div>
                        )}
                    </Col>
                </Row>
            </Container>
        )
    }

    return content
}
export default UsersList