import { useGetReportsQuery } from "./reportsApiSlice"
import Report from "./Report"
import useAuth from "../../hooks/useAuth"
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from "../../hooks/useTitle"
import React, { useState } from "react"
import * as XLSX from 'xlsx';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { faAngleDoubleLeft, faAngleDoubleRight, faFileExcel } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ReportsList = () => {
    useTitle('telerapp - All Reports')

    const { username, isManager, isAdmin, isQA } = useAuth()

    const {
        data: reports,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetReportsQuery('reportsList', {
        pollingInterval: 15000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const [completionFilter, setCompletionFilter] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [showMyReports, setShowMyReports] = useState(localStorage.getItem('myOriginated') === 'true' || isManager ? true : false);

    let content

    if (isLoading) content = (
        <div className="loading-overlay">
            <PulseLoader color={"#1f2a4b"} />
        </div>
    )

    if (isError) {
        content = (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', color: '#717378cd' }}>
                <p>{error?.data?.message}</p>
            </div>
        )
    }

    if (isSuccess) {
        const { ids, entities } = reports

        let filteredIds
        if (isManager || isAdmin || isQA) {
            filteredIds = [...ids]
        } else {
            filteredIds = ids.filter(reportId => entities[reportId].username === username || entities[reportId].access_ids.includes(username))
        }

        filteredIds = filteredIds.filter(reportId => {
            const report = entities[reportId];
            const reportDate = new Date(report.createdat);
            const start = new Date(startDate);
            const end = new Date(endDate);
            return (report.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                report.accessionno.toLowerCase().includes(searchTerm.toLowerCase()) ||
                report.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
                report.originator.toLowerCase().includes(searchTerm.toLowerCase()) ||
                report.priority.toLowerCase().includes(searchTerm.toLowerCase()) ||
                report.modality.toLowerCase().includes(searchTerm.toLowerCase()) ||
                report.institute.toLowerCase().includes(searchTerm.toLowerCase())) &&
                (!startDate || !endDate || (reportDate >= start && reportDate <= end));
        });

        filteredIds = filteredIds.filter(reportId => {
            const report = entities[reportId];
            return showMyReports
                ? report.originator.toLowerCase() === username.toLowerCase()
                : true;
        });

        filteredIds = filteredIds.filter(reportId => {
            const report = entities[reportId];
            return completionFilter ? report.completed === completionFilter : true;
        });

        const reportsPerPage = 50;
        const totalPages = Math.ceil(filteredIds.length / reportsPerPage);

        const startIndex = (currentPage - 1) * reportsPerPage;
        const endIndex = startIndex + reportsPerPage;

        const tableContent = ids?.length && filteredIds.slice(startIndex, endIndex).map(reportId => (
            <Report key={reportId} reportId={reportId} />
        ));

        if (startDate && endDate) {
            const start = new Date(startDate);
            const end = new Date(endDate);
            filteredIds = filteredIds.filter(reportId => {
                const reportDate = new Date(entities[reportId].createdat);
                return reportDate >= start && reportDate <= end;
            });
        }

        const handleButtonClick = () => {
            setShowMyReports(!showMyReports);
            localStorage.setItem('myOriginated', !showMyReports);
        };

        const exportToExcel = () => {
            let wb = XLSX.utils.book_new();
            let ws_name = `${username}_worklist`;

            let ws_data = [['Assigned', 'Accession No', 'Originator', 'Priority', 'Modality', 'Title', 'DOB', 'Sex', 'Institute', 'Status', 'Access IDs', 'Created At', 'Updated At']];

            filteredIds.forEach(reportId => {
                const report = entities[reportId];

                let access_ids = report.access_ids.length > 0 ? report.access_ids.join(',') : 'N/A';

                let institute = report.institute.length > 0 ? report.institute : 'N/A';

                let dob = report?.dob ? report.dob : 'N/A';

                let sex = report?.sex ? report.sex : 'N/A';

                let completedStatus;
                if (report.completed === "true") {
                    completedStatus = "Completed";
                } else if (report.completed === "false") {
                    completedStatus = "Open";
                } else {
                    completedStatus = report.completed;
                }

                ws_data.push([report.username, report.accessionno, report.originator, report.priority, report.modality, report.title, dob, sex, institute, completedStatus, access_ids, report.createdat, report.updatedat]);
            });

            let ws = XLSX.utils.aoa_to_sheet(ws_data);

            XLSX.utils.book_append_sheet(wb, ws, ws_name);

            XLSX.writeFile(wb, `${username}_worklist.xlsx`);
        }

        const handlePrevious = () => {
            setCurrentPage(currentPage - 1);
        };

        const handleNext = () => {
            setCurrentPage(currentPage + 1);
        };

        let startRange = (currentPage - 1) * reportsPerPage + 1;
        let endRange = Math.min(startRange + reportsPerPage - 1, filteredIds.length);
        let totalReports = filteredIds.length;

        content = (
            <>
                <Container fluid className="reports-list">

                    <Row>
                        <Col xs={12}>
                            <div className="search-input-reports-list">
                                <div className="input-group mb-3">
                                    <select
                                        as="select"
                                        style={{ marginBottom: "10px", padding: "0 10px", marginRight: "5px", marginTop: "5px", border: "1px solid #ccc" }}
                                        value={completionFilter}
                                        title="Reports status"
                                        onChange={e => setCompletionFilter(e.target.value)}
                                        className="input-list-select"
                                    >
                                        <option value="">All</option>
                                        <option value="true">Completed</option>
                                        <option value="false">Open</option>
                                        <option value="Approved">Approved</option>
                                        <option value="Read">Read</option>
                                        <option value="Addendum">Addendum</option>
                                        <option value="Final">Final</option>
                                    </select>

                                    <input
                                        style={{ marginBottom: "10px" }}
                                        placeholder="Search..."
                                        aria-label="Search"
                                        aria-describedby="basic-addon2"
                                        className="input-list"
                                        title="Search reports"
                                        value={searchTerm}
                                        onChange={event => setSearchTerm(event.target.value)}
                                    />

                                    <input
                                        type="date"
                                        style={{ marginBottom: "10px" }}
                                        value={startDate}
                                        onChange={e => setStartDate(e.target.value)}
                                        placeholder="mm/dd/yyyy"
                                        name="start date"
                                        className="input-list"
                                        title="start date"
                                    />
                                    <input
                                        type="date"
                                        style={{ marginBottom: "10px" }}
                                        value={endDate}
                                        onChange={e => setEndDate(e.target.value)}
                                        placeholder="mm/dd/yyyy"
                                        name="end date"
                                        className="input-list"
                                        title="end date"
                                    />

                                    <button
                                        className="excel-btn"
                                        variant="outline-secondary"
                                        title="Export to excel"
                                        style={{ marginTop: "5px" }}
                                        onClick={exportToExcel}
                                    >
                                        Export <FontAwesomeIcon icon={faFileExcel} style={{ marginLeft: "5px" }} />
                                    </button>

                                    <button
                                        className={`my-assignment-button ${showMyReports ? "my-assignment-green" : "my-assignment-gray"}`}
                                        style={{ marginTop: "5px", marginRight: '5px' }}
                                        onClick={handleButtonClick}
                                        title="My assignments"
                                    >
                                        <span style={{ fontWeight: "bold" }}>My Assignments</span>
                                    </button>

                                    <button style={{ marginTop: '5px', marginRight: '5px', borderRadius: '0' }}
                                        variant="outline-secondary"
                                        title="Previous page"
                                        className="excel-btn dark-1btn"
                                        onClick={handlePrevious}
                                        disabled={currentPage === 1}
                                    >
                                        <FontAwesomeIcon icon={faAngleDoubleLeft} />
                                    </button>

                                    <button style={{ marginTop: '5px', marginRight: '5px', borderRadius: '0' }}
                                        variant="outline-secondary"
                                        title="Next page"
                                        className="excel-btn dark-1btn"
                                        onClick={handleNext}
                                        disabled={currentPage === totalPages}
                                    >
                                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                                    </button>
                                </div>
                            </div>

                            <Table striped hover responsive>
                                <thead>
                                    <tr>
                                        <th scope="col" className="darktable" >Status</th>
                                        <th scope="col" className="darktable" >TAT</th>
                                        <th scope="col" className="darktable" >Created</th>
                                        <th scope="col" className="darktable" >Updated</th>
                                        <th scope="col" className="darktable" >Priority</th>
                                        <th scope="col" className="darktable" >Modality</th>
                                        <th scope="col" className="darktable" >Title</th>
                                        <th scope="col" className="darktable" >Accession</th>
                                        <th scope="col" className="darktable" >DOB</th>
                                        <th scope="col" className="darktable" >Sex</th>
                                        <th scope="col" className="darktable" >Institution</th>
                                        <th scope="col" className="darktable" >Assigned</th>
                                        <th scope="col" className="darktable" >Edit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableContent}
                                </tbody>
                            </Table>
                            {totalPages > 0 && (
                                <div className="pagination-buttons" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>

                                    <button style={{ marginRight: '10px', marginTop: "5px", borderRadius: '0' }}
                                        variant="outline-secondary"
                                        title="Previous page"
                                        className="excel-btn dark-1btn"
                                        onClick={handlePrevious}
                                        disabled={currentPage === 1}
                                    >
                                        <FontAwesomeIcon icon={faAngleDoubleLeft} />
                                    </button>
                                    <span className="page-number">{currentPage} / {totalPages}</span>
                                    <button style={{ marginLeft: '10px', marginTop: "5px", borderRadius: '0' }}
                                        variant="outline-secondary"
                                        title="Next page"
                                        className="excel-btn dark-1btn"
                                        onClick={handleNext}
                                        disabled={currentPage === totalPages}
                                    >
                                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                                    </button>

                                </div>
                            )}

                            <div className="reports-range-info" style={{ textAlign: 'center', marginTop: '10px' }}>
                                {totalReports > 0 && `Showing ${startRange} to ${endRange} of ${totalReports} reports`}
                            </div>

                            {filteredIds?.length === 0 && (
                                <div className="content" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
                                    <p style={{ color: '#a30000cd' }}>
                                        {`No report found`}
                                    </p>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }

    return content
}
export default ReportsList