import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useSendLogoutMutation } from '../features/auth/authApiSlice';
import logo from '../img/logo-main.png';
import useAuth from '../hooks/useAuth';
import { faUser, faPlus, faBook, faUsers, faArrowRightFromBracket, faFileContract, faSun, faMoon } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DashHeader = () => {
    const { isAdmin, annotationviewer } = useAuth();
    const navigate = useNavigate();

    const [isDarkMode, setIsDarkMode] = useState(() => {
        const savedTheme = localStorage.getItem('isDarkMode');
        return savedTheme === 'true';
    });
    const [logoutSuccess, setLogoutSuccess] = useState(false);
    const [sendLogout, { isSuccess }] = useSendLogoutMutation();

    useEffect(() => {
        if (isSuccess || logoutSuccess) {
            navigate('/');
        }
    }, [isSuccess, logoutSuccess, navigate]);

    useEffect(() => {
        document.body.classList.toggle('dark-mode', isDarkMode);
    }, [isDarkMode]);

    const toggleDarkMode = () => {
        const newTheme = !isDarkMode;
        setIsDarkMode(newTheme);
        localStorage.setItem('isDarkMode', newTheme);
        document.body.classList.toggle('dark-mode', newTheme);
        // window.location.reload();
    };

    const onNewReportClicked = () => navigate('/dash/report/new');
    const onNewUserClicked = () => navigate('/dash/users/new');
    const onReportsClicked = () => navigate('/dash/report');
    const onUsersClicked = () => navigate('/dash/users');
    const onAnnotationClicked = () => window.open('http://3.135.214.96:8000/?key=teler@pp24', '_blank');
    const logoutButton = () => {
        sendLogout();
        setLogoutSuccess(true);
        localStorage.removeItem('myOriginated');
    };

    const content = (
        <>
            <Navbar collapseOnSelect expand="lg" className={`header1 fixed-top shadow ${isDarkMode ? 'navbar-dark bg-dark' : 'bg-body-tertiary'}`}>
                <Container>
                    <Navbar.Brand>
                        <Link to="/dash" title="Dashboard" className='navbar-brand text-success fw-semibold'>
                            <img src={logo} alt='logo' width="100" height="25" />
                        </Link>

                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls='basic-navbar-nav' />
                    <Navbar.Collapse id='basic-navbar-nav'>
                        <Nav className='me-auto justify-content-end w-100'>
                            <Nav.Link onClick={onReportsClicked} eventKey='1' className='nav-link1 text-uppercase'><FontAwesomeIcon icon={faBook} /> WorkList</Nav.Link>
                            <Nav.Link onClick={onNewReportClicked} eventKey='2' className='nav-link1 text-uppercase'><FontAwesomeIcon icon={faPlus} /> New Report</Nav.Link>
                            {isAdmin && <>
                                <Nav.Link onClick={onNewUserClicked} eventKey='3' className='nav-link1 text-uppercase'><FontAwesomeIcon icon={faUser} /> Add User</Nav.Link>
                                <Nav.Link onClick={onUsersClicked} eventKey='4' className='nav-link1 text-uppercase'><FontAwesomeIcon icon={faUsers} /> All Users</Nav.Link>
                            </>}
                            {annotationviewer && <Nav.Link onClick={onAnnotationClicked} eventKey='5' className='nav-link1 text-uppercase'>
                                <FontAwesomeIcon icon={faFileContract} /> Viewer
                            </Nav.Link>}
                            <Nav.Link
                                onClick={toggleDarkMode}
                                title="Theme"
                                eventKey='7'
                                className='text-uppercase'
                                style={{
                                    transition: 'transform 0.3s ease, color 0.3s ease',
                                    marginRight: '20px'
                                }}
                            >
                                {isDarkMode ? (
                                    <FontAwesomeIcon
                                        icon={faMoon}
                                        style={{
                                            color: "#c9cbd1",
                                            fontSize: '1.4rem',
                                            transition: 'transform 0.3s ease, color 0.3s ease',
                                            transform: 'rotate(-360deg)',
                                        }}
                                    />
                                ) : (
                                    <FontAwesomeIcon
                                        icon={faSun}
                                        style={{
                                            color: "#e3bb64",
                                            fontSize: '1.4rem',
                                            transition: 'transform 0.3s ease, color 0.3s ease',
                                            transform: 'rotate(360deg)',
                                        }}
                                    />
                                )}
                            </Nav.Link>

                            <Nav.Link onClick={logoutButton} title="Logout" eventKey='6' className='text-uppercase'><FontAwesomeIcon icon={faArrowRightFromBracket} style={{ color: "#a30000cd" }} /></Nav.Link>
                        </Nav>

                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );

    return content;
};

export default DashHeader;
