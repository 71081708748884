import { Link } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faPlus, faBoxesStacked, faBook, faUsers, faBuilding, faFileAlt, faEnvelopeCircleCheck, faCircleQuestion, faLaptopFile, faChartSimple, faQuestion } from '@fortawesome/free-solid-svg-icons';
import useAuth from '../../hooks/useAuth';
import useTitle from '../../hooks/useTitle';
import { useGetLatestFiveQuestionsAnswersQuery } from '../../features/qa/qaApiSlice';
import { useState } from 'react';

const Welcome = () => {
    const { fullname, isAdmin, isManager, isQA } = useAuth();

    useTitle(`telerapp - Welcome ${fullname}`);

    const date = new Date();
    const today = new Intl.DateTimeFormat('en-US', { dateStyle: 'full' }).format(date);

    const { data: questionsAnswers, error, isLoading } = useGetLatestFiveQuestionsAnswersQuery();

    const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

    const toggleAnswer = (index) => {
        setActiveQuestionIndex(activeQuestionIndex === index ? null : index);
    };

    const content = (
        <Container style={{ paddingTop: '6rem', paddingBottom: '5rem' }}>
            <Row className="justify-content-md-center">
                <Col xs={12} md={6} lg={4} className="text-center">
                    <p>{today}</p>
                    <h2>Welcome, {fullname}</h2>
                    <br />
                </Col>
                <Col xs={12} className="text-center">
                    <Row>
                        {[
                            { to: "/dash/report", icon: faBook, text: "All Reports", desc: "View and manage new reports." },
                            { to: "/dash/report/new", icon: faPlus, text: "Add New Report", desc: "Create a new report." },
                            { to: "/dash/report/archive", icon: faBoxesStacked, text: "Reports Archive", desc: "Fetch all reports." },
                            { to: "/dash/file-viewer", icon: faLaptopFile, text: "File viewer", desc: "PDF and text file viewer" },
                            isAdmin && { to: "/dash/users", icon: faUsers, text: "All Users", desc: "View and manage all users." },
                            isAdmin && { to: "/dash/users/new", icon: faUser, text: "Add New User", desc: "Add a new user to the system." },
                            (!isQA) && { to: "/dash/templates", icon: faFileAlt, text: "Manage Templates", desc: "Manage report and document templates." },
                            (isAdmin || isManager) && { to: "/dash/institutes", icon: faBuilding, text: "Manage Institutes", desc: "Manage institutes information." },
                            isAdmin && { to: "/dash/logs", icon: faFileAlt, text: "Logs", desc: "View system logs." },
                            { to: "/dash/qa", icon: faCircleQuestion, text: "Q&A", desc: "Most asked questions answer." },
                            isAdmin && { to: "/dash/analytics", icon: faChartSimple, text: "Analytics", desc: "View system Analytics." },
                            (isAdmin || isManager) && { to: "/dash/manage-qa", icon: faQuestion, text: "Manage Q&A", desc: "Manage questions answer." },
                            { to: "/dash/feedback", icon: faEnvelopeCircleCheck, text: "Send Feedback", desc: "Mail to our support team." },

                        ].filter(Boolean).map((link, index) => (
                            <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-4">
                                <Card className='dash-cards' style={{ width: '100%', height: '200px' }}>
                                    <Card.Body>
                                        <FontAwesomeIcon icon={link.icon} className="icon-dash mb-2" />
                                        <Card.Title>{link.text}</Card.Title>
                                        <Card.Text>{link.desc}</Card.Text>
                                        <Link className="welcome-links" to={link.to}>
                                            Go to {link.text}
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
            <Row className="justify-content-md-center mt-5">
                <Col xs={12} md={10} lg={8} style={{ maxWidth: '100%' }}>
                    <h3 className="text-center">Latest Questions and Answers</h3>
                    {isLoading && <p>Loading...</p>}
                    {error && <p>Error: {error.message}</p>}
                    <ul className="faq-list">
                        {questionsAnswers?.map((qa, index) => (
                            <li key={qa.id}>
                                <div className="faq-question" onClick={() => toggleAnswer(index)}>
                                    <strong>{qa.question}</strong>
                                    <button className="faq-toggle" aria-label="Toggle answer">
                                        <i className={`fas fa-chevron-${activeQuestionIndex === index ? 'up' : 'down'}`} />
                                    </button>
                                </div>
                                <div className="faq-dash-adark faq-answer" style={{ display: activeQuestionIndex === index ? 'block' : 'none' }}>
                                    <p>{qa.answer}</p>
                                </div>
                            </li>
                        ))}
                    </ul>
                    <div className="text-center mt-4">
                        <Link to="/dash/qa" className="no-underline">
                            View more...
                        </Link>
                    </div>
                </Col>
            </Row>

        </Container>
    );

    return content;
};

export default Welcome;
