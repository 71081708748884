import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faPenToSquare } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from 'react-router-dom'

import { useGetReportsQuery } from './reportsApiSlice'
import { memo } from 'react'
import useAuth from '../../hooks/useAuth'

const Report = ({ reportId }) => {

    const { username } = useAuth()

    // const [darkTheme, setDarkTheme] = useState('');

    // useEffect(() => {
    //     const savedTheme = localStorage.getItem('isDarkMode');
    //     setDarkTheme(savedTheme === 'true');
    // }, []);

    const { report } = useGetReportsQuery("reportsList", {
        selectFromResult: ({ data }) => ({
            report: data?.entities[reportId]
        }),
    })

    const navigate = useNavigate()

    const formatTAT = (tat) => {
        if (tat === null) {
            return "N/A";
        }

        if (tat === 0) {
            return "00:00";
        }

        const hours = Math.floor(tat / 60);
        const minutes = tat % 60;

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    };

    if (report) {
        const created = new Date(report.createdat).toLocaleString('en-US', { day: 'numeric', month: 'long', hour: 'numeric', minute: 'numeric' })

        const updated = new Date(report.updatedat).toLocaleString('en-US', { day: 'numeric', month: 'long', hour: 'numeric', minute: 'numeric' })

        const truncatedTitle = report.title.length > 20 ? `${report.title.slice(0, 20)}...` : report.title;

        const priorityColor = report.priority === 'STAT' ? '#a30000cd' : report.priority === 'ASAP' ? '#008080cd' : 'black';

        const handleEdit = () => navigate(`/dash/report/${reportId}`)

        const getBackgroundColor = () => {
            // if (!darkTheme) {
            //     if (report.priority === 'ASAP') {
            //         return '#E7FFCE'; // light green for ASAP
            //     } else if (report.priority === 'STAT') {
            //         return '#ffe5e5'; // light red for STAT
            //     }
            // }
            // if (darkTheme) {
            if (report.priority === 'ASAP') {
                return 'rgba(0, 100, 0, 0.3)'; // light green for ASAP
            } else if (report.priority === 'STAT') {
                return 'rgba(139, 0, 0, 0.3)'; // light red for STAT
            }
            // }
        };

        return (
            <tr>
                <td style={{
                    backgroundColor: getBackgroundColor(),
                }} className='darktablecontent'>
                    {
                        report.completed === "true" ? (
                            <span style={{ color: '#008080cd', marginRight: '5px' }}>Completed</span>
                        ) : report.completed === "false" ? (
                            <span style={{ color: '#a30000cd', marginRight: '5px' }}>Open</span>
                        ) : report.completed === "Read" ? (
                            <span style={{ color: 'black', marginRight: '5px' }}>Read</span>
                        ) : report.completed === "Approved" ? (
                            <span style={{ color: 'green', marginRight: '5px' }}>Approved</span>
                        ) : report.completed === "Addendum" ? (
                            <span style={{ color: 'orange', marginRight: '5px' }}>Addendum</span>
                        ) : report.completed === "Final" ? (
                            <span style={{ color: '#5e7ea8', marginRight: '5px' }}>Final</span>
                        ) : (
                            <span style={{ color: '#000', marginRight: '5px' }}>{report.completed}</span>
                        )
                    }
                    {(report.username === username && report.notification === "2") ? <FontAwesomeIcon icon={faBell} style={{ color: 'gray' }} /> : ""}
                    {(report.username !== username && report.notification === "1") ? <FontAwesomeIcon icon={faBell} style={{ color: 'gray' }} /> : ""}
                </td>

                <td style={{
                    backgroundColor: getBackgroundColor(),
                    color: '#008080'
                }} className='darktablecontent'>{formatTAT(report.tat)}</td>

                <td style={{
                    backgroundColor: getBackgroundColor(),
                }} className='darktablecontent'>{created}</td>

                <td style={{
                    backgroundColor: getBackgroundColor(),
                }} className='darktablecontent'>{updated}</td>

                <td style={{
                    backgroundColor: getBackgroundColor(), color: priorityColor
                }} className='darktablecontent'>{report.priority}</td>

                <td style={{
                    backgroundColor: getBackgroundColor(),
                }} className='darktablecontent'>{report.modality}</td>

                <td style={{
                    backgroundColor: getBackgroundColor(),
                }} className='darktablecontent' title={report.title}>{truncatedTitle}</td>

                <td style={{
                    backgroundColor: getBackgroundColor(),
                }} className='darktablecontent'>{report.accessionno}</td>

                <td style={{
                    backgroundColor: getBackgroundColor(),
                }} className='darktablecontent'>{!report.dob ? "N/A" : report?.dob}</td>

                <td style={{
                    backgroundColor: getBackgroundColor(),
                }} className='darktablecontent'>{!report.sex ? "N/A" : report?.sex}</td>

                <td style={{
                    backgroundColor: getBackgroundColor(),
                }} className='darktablecontent'>{report.institute === "" ? "N/A" : report.institute}</td>

                <td style={{
                    backgroundColor: getBackgroundColor(),
                }} className='darktablecontent'>{report.username}</td>

                <td style={{
                    backgroundColor: getBackgroundColor(),
                }} className='darktablecontent'>
                    <button
                        onClick={handleEdit}
                        className='edit-button'
                        title="Edit"
                    >
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                </td>
            </tr>
        )

    } else return null
}

const memoizedReport = memo(Report)

export default memoizedReport