import React, { useState } from 'react';
import { Container, Row, Col, Card, Toast } from 'react-bootstrap';
import { useCreateInstituteMutation, useEditInstituteMutation, useGetInstitutesQuery, useDeleteInstituteMutation } from './instituteApiSlice';
import useTitle from '../../hooks/useTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSave, faTrashCan } from '@fortawesome/free-solid-svg-icons';

const InstitutePage = () => {
    useTitle(`telerapp - Institutes Manager`);

    const [institute, setInstitute] = useState('');
    const [oldInstitute, setOldInstitute] = useState('');
    const [newInstitute, setNewInstitute] = useState('');
    const [deleteInstituteName, setDeleteInstituteName] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');

    const [createInstitute, { isLoading: isCreating }] = useCreateInstituteMutation();
    const [editInstitute, { isLoading: isEditing }] = useEditInstituteMutation();
    const [deleteInstitute, { isLoading: isDeleting }] = useDeleteInstituteMutation();

    const { data: institutes, refetch } = useGetInstitutesQuery();

    const handleCreate = async (e) => {
        e.preventDefault();
        try {
            await createInstitute(institute).unwrap();
            setToastMessage({ text: 'Institute created successfully', isSuccess: true });
            setShowToast(true);
            setInstitute('');
            refetch();
        } catch (error) {
            setToastMessage({ text: `Failed to create institute: ${error.message}`, isSuccess: false });
            setShowToast(true);
        }
    };

    const handleEdit = async (e) => {
        e.preventDefault();
        try {
            await editInstitute({ oldInstitute, newInstitute }).unwrap();
            setToastMessage({ text: 'Institute updated successfully', isSuccess: true });
            setShowToast(true);
            setOldInstitute('');
            setNewInstitute('');
            refetch();
        } catch (error) {
            setToastMessage({ text: `Failed to edit institute: ${error.data?.message}`, isSuccess: false });
            setShowToast(true);
        }
    };

    const handleDelete = async (e) => {
        e.preventDefault();
        const confirmDelete = window.confirm("Are you sure you want to delete this institute?");
        if (confirmDelete) {
            try {
                await deleteInstitute(deleteInstituteName).unwrap();
                setToastMessage({ text: 'Institute deleted successfully', isSuccess: true });
                setShowToast(true);
                setDeleteInstituteName('');
                refetch();
            } catch (error) {
                setToastMessage({ text: `Failed to delete institute: ${error.data?.message}`, isSuccess: false });
                setShowToast(true);
            }
        }
    };

    return (
        <>
            <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide style={{
                position: 'fixed',
                top: '50%',
                right: '50px',
                width: '50%',
                transform: 'translateY(-50%)',
                zIndex: 1050,
                color: toastMessage.isSuccess ? 'green' : 'red'
            }}>
                <Toast.Header>
                    <strong className="mr-auto">{toastMessage.isSuccess ? 'Successful' : 'Error'}</strong>
                </Toast.Header>
                <Toast.Body>{toastMessage.text}</Toast.Body>
            </Toast>
            <Container style={{ paddingTop: '6rem', paddingBottom: '7rem' }}>
                <Row className="justify-content-md-center">
                    <Col xs={12} md={8} lg={6}>
                        <label style={{ fontWeight: 'bold' }}>Add Institute : </label>
                        <Card className="mb-3 darktEditUsercontent">
                            <Card.Body>
                                <form onSubmit={handleCreate}>
                                    <input
                                        type="text"
                                        placeholder=' Institute name'
                                        title='Institute name'
                                        style={{ borderRadius: '6px', padding: '4px 8px', border: '2px solid #ccc', marginRight: '10px' }}
                                        value={institute}
                                        onChange={(e) => setInstitute(e.target.value)}
                                        required
                                    />
                                    <button type="submit" title="Add institute" className="institute-Button" style={{ marginTop: '5px' }} disabled={isCreating}>
                                        <FontAwesomeIcon icon={faSave} /> {isCreating ? 'Saving...' : ``}
                                    </button>
                                </form>
                            </Card.Body>
                        </Card>

                        <label style={{ fontWeight: 'bold' }}>Edit Institute : </label>
                        <Card className="mb-3 darktEditUsercontent">
                            <Card.Body>
                                <form onSubmit={handleEdit}>
                                    <input
                                        type="text"
                                        placeholder=' Current name'
                                        title='Current institute name'
                                        style={{ borderRadius: '6px', padding: '4px 8px', border: '2px solid #ccc', marginRight: '10px' }}
                                        value={oldInstitute}
                                        onChange={(e) => setOldInstitute(e.target.value)}
                                        required
                                    />

                                    <input
                                        type="text"
                                        placeholder=' New name'
                                        title='New institute name'
                                        style={{ borderRadius: '6px', padding: '4px 8px', border: '2px solid #ccc', marginRight: '10px', marginTop: '5px' }}
                                        value={newInstitute}
                                        onChange={(e) => setNewInstitute(e.target.value)}
                                        required
                                    />

                                    <button type="submit" title="Edit institute" className="institute-Button" style={{ marginTop: '5px' }} disabled={isEditing}>
                                        <FontAwesomeIcon icon={faEdit} />{isEditing ? 'Editing...' : ''}
                                    </button>
                                </form>
                            </Card.Body>
                        </Card>

                        <label style={{ fontWeight: 'bold' }}>Delete Institute : </label>
                        <Card className="mb-3 darktEditUsercontent">
                            <Card.Body>
                                <form onSubmit={handleDelete}>
                                    <input
                                        type="text"
                                        placeholder=' Institute name'
                                        title='Institute name'
                                        style={{ borderRadius: '6px', padding: '4px 8px', border: '2px solid #ccc', marginRight: '10px' }}
                                        value={deleteInstituteName}
                                        onChange={(e) => setDeleteInstituteName(e.target.value)}
                                        required
                                    />

                                    <button type="submit" title="Delete institute" className="institute-Button2" style={{ marginTop: '5px' }} disabled={isDeleting}>
                                        <FontAwesomeIcon icon={faTrashCan} />{isDeleting ? 'Deleting...' : ''}
                                    </button>
                                </form>
                            </Card.Body>
                        </Card>

                        <div className="institute-list">
                            <ul title="All institues">
                                {institutes?.map(institute => (
                                    <li key={institute.institute_list}>{institute.institute_list}</li>
                                ))}
                            </ul>
                        </div>

                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default InstitutePage;